@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* .input__error {
    @apply relative w-full min-w-[180px] min-h-[30px] rounded-[25px] bg-transparent border-[2px] text-[#F9F9F9] text-base pl-[15px] py-[5px] min-[744px]:text-[20px] min-[1170px]:text-[22px] min-[1130px]:py-[10px] min-[1130px]:pl-[70px] min-[1440px]:text-[24px] border-red-700;
  } 

  .input__success {
    @apply relative w-full min-w-[180px] min-h-[30px] rounded-[25px] bg-transparent border-[2px] border-[#F9F9F9] text-[#F9F9F9] text-base pl-[15px] py-[5px] min-[744px]:text-[20px] min-[1170px]:text-[22px] min-[1130px]:py-[10px] min-[1130px]:pl-[70px] min-[1440px]:text-[24px];
  }

  .form__validation-text {
    @apply absolute top-[7px] right-[10px] text-red-600 text-base min-[744px]:text-[20px] min-[1130px]:top-[10px] min-[1130px]:right-[25px] min-[1170px]:text-[22px] min-[1440px]:text-[24px] min-[1440px]:top-[12px];
  }

  .form__submit-btn {
    @apply rounded-[25px] py-[3px] px-[20px] bg-transparent border-[2px] border-[#F9F9F9] text-[#F9F9F9] text-base min-[744px]:text-[20px] min-[1170px]:text-[22px] min-[1130px]:py-[10px] min-[1440px]:text-[24px];
  }

  .form__submit-btn:disabled {
    @apply opacity-[0.35];
  } */
}

* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: -apple-system, BlinkMacSystemFont, Arial, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

p {
  white-space: pre-line;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-menu-list {
  font-family: Arial;
  font-weight: bold;
}

.form__submit-btn:disabled {
  opacity: 0.35;
}

.backdrop-hero {
  /* background-image: url('/public/pictures/Home/mobile-hero-clean.png'); */
  /* width: 500px;
  height: 500px; */
}

.backdrop2-hero {
  background: rgba(0, 77, 165, 0.8);
  backdrop-filter: blur(2.5px);
  /* width: 500px;
  height: 500px; */
}

.backdrop3-hero {
  background-image: radial-gradient(
    1254.59% 92.53% at 50% 100%,
    #121111 18.23%,
    rgba(0, 77, 165, 0) 78.65%,
    rgba(18, 17, 17, 0) 99.99%
  );
}

.backdrop__about-us {
  background-image: url('/public/pictures/Home/desktop-about-us-clean.png');
}

.backdrop__about-us2 {
  background: linear-gradient(
    0deg,
    #121111 26.04%,
    rgba(159, 190, 217, 0.3) 100%
  );
}

.about__us-text {
  color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

/* .services-img { */
/* background-image: url('/public/pictures/Home/mobile-services-1-clean.png'); */
/* } */

.services-backdrop {
  background: linear-gradient(
    360deg,
    rgba(0, 77, 165, 0.5) 0%,
    rgba(0, 77, 165, 0) 100%
  );
  backdrop-filter: blur(2.5px);
}

.services-title {
  color: #f9f9f9;
  font-family: Arial;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}

.footer-backdrop {
  background: linear-gradient(
    180deg,
    rgba(0, 77, 165, 0.8) 0%,
    rgba(0, 77, 165, 0) 100%
  );
  backdrop-filter: blur(2.5px);
}

.table__title {
  color: #fff;
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}

.table__text {
  color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

/* .backdrop::before {
  content: '';
  background-image: radial-gradient(
    1254.59% 92.53% at 50% 100%,
    #121111 18.23%,
    rgba(0, 77, 165, 0) 78.65%,
    rgba(18, 17, 17, 0) 99.99%
  );
  width: 500px;
  height: 500px;
} */
@media (min-width: '350px') {
  .service-size {
    height: 140px;
  }
}

/* @media (min-width: '600px') {
  .services-title {
    font-size: 18px;
  }
}

@media screen (min-width: '650px') {
  .services-title {
    font-size: 20px;
  }
} */

@media (min-width: 744px) {
  .header-height-744 {
    height: 100px;
  }

  .header-menu-list {
    font-size: 16px;
  }

  .menu-list-hide744 {
    display: none;
  }

  .menu-list-show744 {
    display: flex;
  }

  /* .menu-list-flex {
    display: flex;
  } */
}

@media (min-width: 1024px) {
  .header-height-744 {
    height: 100px;
  }

  .header-menu-list {
    font-size: 16px;
  }

  .menu-list-hide1024 {
    display: none;
  }

  .menu-list-show1024 {
    display: flex;
  }

  /* .menu-list-flex {
    display: flex;
  } */
}
