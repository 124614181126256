.video__small {
  display: block;
}

.video__standard {
  display: none;
}

.video__widescreen {
  display: none;
}

@media (min-width: 744px) {
  .video__small {
    display: none;
  }

  .video__standard {
    display: block;
  }
  .video__widescreen {
    display: none;
  }
}

@media (min-width: 1280px) {
  .video__standard {
    display: none;
  }
  .video__widescreen {
    display: block;
  }
}
