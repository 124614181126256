.heroVideo__section {
  height: auto;
  margin-bottom: 30px;
  /* height: 500px; */
}

@media (min-width: 744px) {
  /* .heroVideo__section {
    height: 650px;
  } */
}

@media (min-width: 1140px) {
  /* .heroVideo__section {
    height: 700px;
  } */
}

@media (min-width: 1200px) {
  /* .heroVideo__section {
    height: 750px;
  } */
}

@media (min-width: 1280px) {
  /* .heroVideo__section {
    height: 900px;
  } */
}
